<template>
  <div class="data-generate identify-fake">
    <div class="generate-content">
      <div class="generate-area">
        <div class="under-attack">
          <div class="custom-block inner">
            <h4 class="sub-title">被攻击方</h4>
            <el-image :src="data.underAttackUrl" v-if="data.underAttackUrl" lazy fit="contain">
              <div slot="placeholder" v-loading="true" class="loading"></div>
            </el-image>
            <div v-else class="uploader" @click="chooseExampleFile('underAttackUrl')">
              <img src="@/assets/img/icon-upload.png" alt="" class="icon-upload">
            </div>
            <div class="upload-desc" :class="data.loading ? 'disabled' : ''">
              <div class="upload-btn">
                <div class="upload-text" @click="chooseExampleFile('underAttackUrl')">
                  <p class="text">上传图片</p>
                </div>
              </div>
              <!-- <p class="desc">请上传包含正面人脸的图片</p> -->
            </div>
          </div>
        </div>
        <div class="custom-block result-box">
          <div class="inner">
            <h4 class="sub-title result">生成结果</h4>
            <div v-if="!data.loading">
              <div class="video-panel" v-if="data.videoObj && data.videoObj.all">
                <div class="main-video">
                  <video :src="data.videoObj.all" muted controls autoplay loop x5-playsinline="" playsinline="" webkit-playsinline="" x5-video-player-type="h5" x5-video-player-fullscreen="" x5-video-orientation="portraint" class="video">您的浏览器不支持 video 标签。</video>
                </div>
                <div class="video-list">
                  <div class="small-video" v-for="(item, index) in generateList" :key="index">
                    <div class="box">
                      <video :src="data.videoObj[item.key]" muted autoplay loop x5-playsinline="" playsinline="" webkit-playsinline="" x5-video-player-type="h5" x5-video-player-fullscreen="" x5-video-orientation="portraint" class="video">您的浏览器不支持 video 标签。</video>
                    </div>
                    <p>{{item.text}}</p>
                  </div>
                </div>
              </div>
              <div class="no-data-panel" v-else>
                <img src="@/assets/img/icon-no-data.png" alt="" width="120" height="120">
                <p>暂无数据</p>
              </div>
            </div>
            <div class="qr-scanner" v-if="data.loading">
              <div class="box">
                <div class="line"></div>
                <div class="angle"></div>
              </div>
              <p class="tip">生成中</p>
            </div>
            <div class="export-desc" v-if="data.videoObj && data.videoObj.all && !data.loading">
              <div class="export-btn" @click="downloadVideo">
                <img src="@/assets/img/icon-export.png" alt="" width="16" height="16">
                <span>导出</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="footer">
      <el-button type="primary" @click="onCreate" :disabled="btnDisabled">开始生成</el-button>
    </div>

    <choose-example-radio
      v-if="chooseShowRadio"
      :dataList="customExampleList"
      @closeDialogItem="hideExampleRadio"
      :type="activeKey"
      :fileType="fileType"
      @onChoose="onChoose">
    </choose-example-radio>
  </div>
</template>

<script>
import store, {dispatch, ACTIONS, state, TYPE, commit} from '@/store';
import { Loading } from 'element-ui';
import { STORAGE, CODE_CLASS } from '@/utils/const';
import { downloadVideo, goLoginPageAndInitData } from '@/utils';
import ChooseExampleRadio from "@/components/ChooseExampleRadio.vue";

const ACTIVE_KEY = 'vivoDetectionData';

const GENERATE_LIST = [
  {key: 'shakeHeadLeft', text: '向左转头'},
  {key: 'shakeHeadRight', text: '向右转头'},
  {key: 'blink', text: '眨眼'},
  {key: 'nod', text: '点头'},
  {key: 'mouth', text: '张嘴'}
]

export default {
  name: "identify-fake-attack",
  components: {
    "choose-example-radio": ChooseExampleRadio,
  },
  data() {
    return {
      activeKey: ACTIVE_KEY,
      chooseShowRadio: false,
      fileType: CODE_CLASS.moduleKey[ACTIVE_KEY],
      activeUploadKey: '', // 上传后需要对应的字段

      generateList: GENERATE_LIST
    }
  },

  mounted() {
    this.getDemo();
  },

  computed: {
    customExampleList() {
      return state.demoData[this.fileType];
    },
    data() {
      return state[ACTIVE_KEY] || {};
    },
    btnDisabled() {
      return !this.data.underAttackUrl || this.data.loading;
    },
  },

  methods: {
    getDemo() {
      dispatch(ACTIONS.getDemo, {fileType: this.fileType});
    },

    commitData(data) {
      commit(TYPE.SAVE_VIVO_DETECTION_DATA, data);
    },

    // 开始生成
    onCreate() {
      let {attackUrl, underAttackUrl} = this.data;
      dispatch(ACTIONS.vivoDetection, {image: underAttackUrl});
    },

    downloadVideo (){
      let {videoObj = {}} = this.data;
      videoObj.all && downloadVideo(videoObj.all);
    },

    // 选择示例
    chooseExampleFile(key) {
      if (this.data.loading) {
        return;
      }
      this.chooseShowRadio = true;
      this.activeUploadKey = key;
    },
   
    hideExampleRadio(){
      this.chooseShowRadio = false;
    },

    // 选择
    onChoose(fileUrl) {
      this.chooseShowRadio = false;
      this.commitData({...this.data, [this.activeUploadKey]: fileUrl, resultUrl: '', videoObj: {}});
    },
  },
};
</script>

<style lang="less">
</style>
